import { CSSProperties, KeyboardEvent, useCallback } from 'react';
import Image from 'next/image';
import { IKImage } from 'imagekitio-react';
import { Box, Typography } from '@mui/material';
import { IBlog } from '~/data/types/blog/blogType';
import { COLORS } from '~/data/ui/colors';
import { EActionEvent, ECategoryEvent, useEvents } from '~/hooks/useEvents';
import { imageKitThumb } from '~/data/imageKitData';
import { useImageKitS3 } from '~/hooks/useImageKitS3';
import { BLOG_CARD_TEXTS } from './data';
import classes, {
  AllBlogLink,
  LeftStyleIconStyle,
  blogCardContainerStyle,
  blogImageStyle,
  blogImageTagStyle,
  readingTime,
  recommendedText,
  title,
} from './useStyles';
import { useRouter } from 'next/router';
import DoubleLeftIcon from '~/assets/icons/jsx/DoubleLeftIcon';
import { KEYBOARD_TYPES } from '~/data/constants';

interface BlogCardProps {
  blog: IBlog;
  url: string;
  customStyle?: CSSProperties;
  additionalAriaLabel?: string;
  isHomePage?: boolean;
  tabIndex?: number;
}

const BlogCard = ({
  blog,
  url,
  customStyle,
  additionalAriaLabel,
  isHomePage = false,
  tabIndex,
}: BlogCardProps) => {
  const events = useEvents();
  const imageUrl = useImageKitS3;
  const router = useRouter();

  const handleContinueRead = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (blog.title) {
        events.sendEvent(
          {
            action: isHomePage
              ? EActionEvent.HOMEPAGE_BLOG_ARTICLE_CLICK
              : EActionEvent.BLOG_READ_MORE,
            category: ECategoryEvent.BLOG,
          },
          {
            articleName: blog.title,
          },
        );
      }

      router.push(url);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [blog.title, events, isHomePage],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === KEYBOARD_TYPES.ENTER) {
        handleContinueRead(e);
      }
    },
    [handleContinueRead],
  );

  const articleImage =
    typeof blog.mainCardPictureUrl === 'string'
      ? imageUrl(blog.mainCardPictureUrl)
      : blog.mainCardPictureUrl;

  const renderImage = () => {
    if (typeof articleImage === 'string') {
      return (
        <Box sx={blogImageStyle}>
          <IKImage
            src={articleImage}
            style={blogImageTagStyle}
            transformation={[{ n: imageKitThumb.blogCard }]}
            loading="lazy"
          />
        </Box>
      );
    } else if (articleImage && typeof articleImage === 'object') {
      return (
        <Box sx={blogImageStyle}>
          <Image
            alt={blog.title}
            src={articleImage.src}
            width={articleImage.width}
            height={articleImage.height}
            style={blogImageTagStyle}
            loading="lazy"
          />
        </Box>
      );
    }
    return null;
  };

  return (
    <Box
      sx={{ ...blogCardContainerStyle, ...customStyle }}
      onClick={(e) => handleContinueRead(e)}
      aria-label={`${BLOG_CARD_TEXTS.card} ${additionalAriaLabel ?? ''} ${
        blog.title
      } ${blog.readingTime} ${BLOG_CARD_TEXTS.min} ${BLOG_CARD_TEXTS.read}, ${
        BLOG_CARD_TEXTS.toBlog
      }`}
      onKeyDown={handleKeyDown}
      tabIndex={tabIndex || 0}
      role="link"
      className="blog-card-cta"
    >
      <Box sx={classes.imageSection}>{renderImage()}</Box>

      <Box sx={classes.content}>
        <Box sx={classes.creationDetails}>
          {blog.labelTitle && (
            <>
              <Typography
                variant="body1"
                style={{
                  ...recommendedText,
                  background: blog.labelColorHex,
                }}
              >
                {blog.labelTitle}
              </Typography>
              <Typography
                style={{
                  color: COLORS.primary.dark2,
                }}
              >
                {BLOG_CARD_TEXTS.dot}{' '}
              </Typography>
            </>
          )}

          <Typography style={readingTime}>
            {blog.readingTime} {BLOG_CARD_TEXTS.min}
          </Typography>
        </Box>
        {/* Content */}
        <Box sx={classes.textAndBtn}>
          <Typography component={'h3'} variant="body1" sx={title}>
            {blog.title}
          </Typography>
          <Box sx={classes.allBlogButton} className="hover-highlight">
            <AllBlogLink
              href={url}
              aria-label={`${BLOG_CARD_TEXTS.toBlog} ${blog.title}`}
              tabIndex={-1}
            >
              <Typography>{BLOG_CARD_TEXTS.toBlog}</Typography>
              <DoubleLeftIcon Component={LeftStyleIconStyle} />
            </AllBlogLink>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BlogCard;
