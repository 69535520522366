import { SxProps, styled } from '@mui/system';
import Link from 'next/link';
import { CSSProperties } from 'react';
import { COLORS } from '~/data/ui/colors';
import theme from '~/theme';

const classes = {
  imageSection: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flex: 1,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: '100px',
    color: COLORS.black.main,
    flex: 1,
  },

  creationDetails: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '16px',
    gap: '7px',
    height: '20px',
  },

  allBlogButton: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'right',
    textDecoration: 'none',
  },
  textAndBtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'right',
    textDecoration: 'none',
    justifyContent: 'space-around',
    marginTop: '12px',
    gap: '20px',
  },
  blogImageStyle: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '6px',
    maxHeight: '219px',
  },
  fontWeight: {
    fontWeight: 'bold !important',
    color: `${COLORS.green.dark5} !important`,
    '&:hover': {
      color: `${COLORS.black.dark} !important`,
      cursor: 'pointer',
      '& .hover-highlight': {
        color: `${COLORS.black.dark} !important`,
      },
    },
  },
};

export const blogImageStyle = {
  width: '100%',
  height: '151px',
  objectFit: 'cover',
  borderRadius: '6px',
  overflow: 'hidden',

  [theme.breakpoints.up('md')]: {
    height: '188px',
  },
};

export const blogImageTagStyle: CSSProperties = {
  objectFit: 'cover',
  borderRadius: '6px',
  width: '100%',
};
export const readingTime = {
  fontSize: '16px',
  color: COLORS.gray.dark,
  lineHeight: '1.6px',
};
export const recommendedText = {
  fontSize: '14px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 14px',
  gap: '10px',
  background: COLORS.primary.light2,
  borderRadius: '40px',
};
export const title: SxProps = {
  textAlign: 'right',
  fontWeight: '400',
  fontFamily: 'EricaSansFOT,GillSans',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: '3',
  whiteSpace: 'pre-wrap',
  height: '60px',
  fontSize: '16px',
  lineHeight: '1.3',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    height: '70px',
  },
};

export const LeftStyleIconStyle = styled('svg')(() => ({
  height: '14px',
}));

export const AllBlogLink = styled(Link)(() => ({
  position: 'relative',

  display: 'flex',
  alignItems: 'center',
  textAlign: 'right',
  gap: '1px',
  textDecoration: 'none',
  margin: '12px 0 5px',

  '& p': {
    fontSize: '16px',
    lineHeight: '1.3',
  },

  '& svg': {
    marginTop: '-2px',
    width: '16px',
  },

  '&:after': {
    position: 'absolute',
    content: '""',
    backgroundColor: COLORS.yellow.main,
    width: '97%',
    height: '2px',
    left: 0,
    right: '-6px',
    margin: '0 auto',
    bottom: '2px',
  },

  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
  },
}));

export const blogCardContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '16px',
  width: '258px',
  maxWidth: 'calc(100vw - 16px)',
  color: COLORS.white.main,
  border: '1px solid transparent',
  boxShadow: '0px 15px 20px 0px rgba(0, 0, 0, 0.10)',
  backgroundColor: 'white',
  borderRadius: '8px',
  cursor: 'pointer',
  position: 'relative',
  top: 0,
  transition: 'top ease 0.5s',
  textDecoration: 'none',

  '&:hover': {
    border: `1px solid ${COLORS.primary.main}`,
  },

  [theme.breakpoints.up('md')]: {
    width: '282px',
  },
};

export default classes;
