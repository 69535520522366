import React from 'react';
import { ImageFile } from '~/data/types/images';

const DoubleLeftIcon: React.FC<ImageFile> = ({
  className,
  style,
  Component = 'svg',
}) => {
  return (
    <Component
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M10.2535 12.7658L5.74757 8.25987L10.2535 3.75391"
        stroke="black"
        strokeWidth="1.15868"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.2524 12.6992L11.7464 8.19322L16.2524 3.68726"
        stroke="black"
        strokeWidth="1.15868"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Component>
  );
};

export default DoubleLeftIcon;
