import { useMemo } from 'react';

export const useImageKitS3 = (url: string) => {
  return useMemo(() => {
    const imgKit = process.env.NEXT_PUBLIC_IMAGEKIT_URL;
    const stringUrl = typeof url === 'string';

    if (!stringUrl) {
      return url;
    }
    // If the environment variable isn't set or the URL already includes the ImageKit domain, return the URL.
    if (!imgKit || url.includes(imgKit)) {
      return url;
    }
    // Otherwise, replace the S3 bucket URLs and prepend the ImageKit domain.
    return `${imgKit}/${url
      .replace(`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET1}`, '')
      .replace(`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET2}`, 'B2/')}`;
  }, [url]);
};

export const useImagesKitS3 = (urls: string[] | object[]) => {
  return useMemo(() => {
    if (!Array.isArray(urls)) {
      console.error('urls is not an array');
      return [];
    }

    return urls
      .map((item) => {
        let url: string;

        if (typeof item === 'string') {
          url = item;
        } else if (item && typeof item === 'object' && 'url' in item) {
          url = item.url as string;
        } else {
          console.error('Invalid item type');
          return null;
        }

        if (typeof url !== 'string') {
          console.error('url is not a string');
          return url;
        }

        return url.includes(
          process.env.NEXT_PUBLIC_IMAGEKIT_URL ?? 'imageKit.io',
        )
          ? url
          : `${process.env.NEXT_PUBLIC_IMAGEKIT_URL}/${url
              .replace(`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET1}`, '')
              .replace(`${process.env.NEXT_PUBLIC_AWS_S3_BUCKET2}`, 'B2/')}`;
      })
      .filter(Boolean); // Remove null or invalid items
  }, [urls]);
};
