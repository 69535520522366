import { IObjMeta } from '~/data/car/carColors';
import { ROUTES } from './routes';

export const META_TEXT = {
  HOMEPAGE_TITLE: 'freesbe: כל מה שרכב, רק תבחרו',
  HOMEPAGE_DESCRIPTION:
    'בפריסבי תוכלו לבחור רכב חדש לקנייה, יד שנייה, ליסינג והשכרה במגוון מותגים ודגמים, בהתאם לצרכים שלכם בתנאים נוחים ובמחירים מפתיעים',
  FINANCE_TITLE: 'freesbe | מימון לרכב הבא שלך',
  FINANCE_DESCRIPTION:
    'חברת המימון שלנו קיימת משנת 2008 ומאפשרת לך לרכוש את המכונית בנוחות ובגמישות מקסימלית.',
  INSURANCE_TITLE: 'freesbe | ביטוח רכב בתנאים אטרקטיביים',
  INSURANCE_DESCRIPTION:
    'עיקר ההתמחות שלנו הוא בביטוח המכונית תוך בדיקה מול חברות הביטוח השונות והמובילות בשוק ולטובת מתן הצעת המחיר הטובה, האטרקטיבית והמותאמת ביותר לרכבך ובשילוב תנאי יבואן בלעדיים!',
  ABOUT_TITLE: 'freesbe | אודות',
  ABOUT_DESCRIPTION:
    'כל המידע אודות freesbe מקבוצת קרסו מוטורס, יבואנית וותיקה ומוכרת, הפועלת החל משנת 1933 בעולמות הרכב בישראל',
  RESULT_PAGE_TITLE: 'freesbe | חפשו רכב חדש בפריסבי',
  RESULT_PAGE_DESCRIPTION:
    'המכוניות של חלומותיך מעבר לפינה. מבחר ענק של מכוניות חדשות לקנייה ולליסינג במיוחד בשבילך.',
  RESULT_PAGE_LEASE_TITLE: 'freesbe | חפשו רכב ליסינג בפריסבי',
  RESULT_PAGE_LEASE_DESCRIPTION:
    'המכוניות של חלומותיך מעבר לפינה. מבחר ענק של מכוניות חדשות לקנייה ולליסינג במיוחד בשבילך.',
  CONTACT_US_PAGE_TITLE: 'freesbe | דברו איתנו',
  CONTACT_US_PAGE_DESCRIPTION:
    'צריכים עזרה בקניית רכב חדש, יד שנייה או ליסינג? צרו איתנו קשר',
  RESULT_PAGE_USED_CAR_TITLE: 'חפשו רכב יד שנייה בפריסבי | freesbe',
  RESULT_PAGE_USED_CAR_DESCRIPTION:
    'המכוניות של חלומותיך מעבר לפינה. מבחר ענק של מכוניות יד שניה למכירה ולטרייד אין במיוחד בשבילך.',
  WISHLIST_TITLE: 'freesbe | רכבים ששמרתי',
  WISHLIST_DESCRIPTION: 'המכוניות השמורות שלי במקום אחד',
  TERMS_OF_USE_DESCRIPTION:
    'קראו את תנאי השימוש המסדירים את תנאי השימוש באתר freesbe',
  TERMS_OF_USE: 'freesbe | תנאי שימוש',
  PRIVACY_DESCRIPTION:
    'פריסבי מכבדת את פרטיותך כלקוח קבוצת קרסו מוטורס או כמשתמש באתרים המופעלים על ידה כיבואנית ומפיצה של כלי רכב',
  PRIVACY: 'freesbe | מדיניות פרטיות',
  ACCESSIBILITY_DESCRIPTION:
    'פריסבי משקיעה מאמץ ומשאבים על מנת לספק ללקוחותיה שירות נגיש ומכובד. הכנסו לקריאת הפרטים המלאים',
  ACCESSIBILITY: 'freesbe | הצהרת נגישות',
  ACCESSIBILITY_BRANCHES: 'freesbe | הצהרת נגישות - סניפים',
  REFUNDS_DESCRIPTION:
    'הכנסו וקראו את תנאי החזרת רכבים לפריסבי לאחר רכישה או עסקת ליסינג',
  REFUNDS: 'freesbe | מדיניות החזרות',
  BLOG: 'הבלוג של freesbe',
  YEAR: 'שנת',
  EVEASY_PACKAGES: 'freesbe | Eveasy תקנון',
  EVEASY_PACKAGES_DESCRIPTION: 'Eveasy תקנון',
  CONDITIONS: 'תקנון | freesbe',
  CONDITIONS_DESCRIPTION: 'הכנסו וקראו את פרטי התקנונים השונים שלנו',
  SERVICE_AGREEMENT_TITLE: 'אמנת שירות ביטוח | freesbe',
  SERVICE_AGREEMENT_DESCRIPTION: 'אמנת שירות ביטוח',
  CHARGING_SOLUTIONS_TITLE: 'פתרונות טעינה | freesbe',
  CHARGING_SOLUTIONS_DESCRIPTION:
    'קראו על פתרונות הטעינה של פריסבי לרכבים החשמליים שברשותכם',
  USED_CAR_PAGE_TITLE: 'יד שניה למכירה | freesbe',
  LOCATION_PAGE: 'סניפי מכירה והשכרת הרכב שלנו | freesbe',
  LOCATION_PAGE_DESCRIPTION:
    'רוצים לקנות או לשכור רכב בישראל? בואו לראות את פריסת עשרות סניפי פריסבי ברחבי הארץ',
  BLOG_MAIN_TITLE: 'בלוג פריסבי - טיפים לרכישה, ליסינג והשכרת רכב | freesbe',
  BLOG_MAIN_DESCRIPTION:
    'בבלוג שלנו תמצאו טיפים להשכרה,ליסינג או רכישת רכב. אם זה ביטוח, מימון או כתבות על האביזרים השונים, פריסבי היא המקור שלך לתוכן איכותי.',
  SELL_YOUR_CAR_PAGE: ' רוצה למכור רכב | freesbe',
  SELL_YOUR_CAR_DESCRIPTION:
    ' רוצים למכור את הרכב? אנחנו בפריסבי כאן בשביל לעזור.',
  COMMUNITY_TITLE: 'פעילויות פריסבי בקהילה | freesbe',
  COMMUNITY_DESCRIPTION:
    'כקבוצת הרכב הגדולה בארץ, חרטנו על דגלנו גם את חובתנו החברתית ולשם כך, מידי שנה אנו פועלים על מנת לסייע לארגונים ורשויות לצמוח ולהתפתח, לתרום ולהיתרם וככלל, לעשות טוב.',
  LEASING_TITE: 'ליסינג עסקי | freesbe',
  LEASING_DESCRIPTION:
    'אנחנו בפריסבי מעניקים מגוון שירותי רכב ללקוחות עסקיים קטנים כגדולים.מחפשים ליסינג עסקי? אצלנו תמצאו מבחר גדול של רכבים ובעיקר שירות וליווי אישי בכל שלבי העסקה. כל זה תחת מחויבות בלתי מתפשרת לאמינות ושקיפות.',
  NEW_CAR_LOBBY: 'freesbe | רכבים חדשים למכירה',
  NEW_CAR_LOBBY_DESCRIPTION:
    'מוכנים לרכב הבא שלכם? פריסבי מציעה לכם מגוון של רכבים חדשים מהיצרנים המובילים בעולם ובתנאים מעולים.',
  PRIVATE_LEASING_LOBBY: 'ליסינג פרטי - מבחר רכבים בתנאים נוחים | freesbe',
  PRIVATE_LEASING_LOBBY_DESCRIPTION:
    'מוכנים לרכב הבא שלכם? פריסבי מציעה לכם מגוון של רכבי ליסינג מהיצרנים המובילים בעולם ובתנאים מעולים.',
  USED_CAR_LOBBY: 'freesbe | רכבים יד שנייה למכירה',
  USED_CAR_LOBBY_DESCRIPTION:
    'מוכנים לרכב הבא שלכם? פריסבי מציעה לכם מגוון של רכבים יד שנייה מהיצרנים המובילים בעולם ובתנאים מעולים.',
  FLEET_CAR_LOBBY: 'ציי רכב לחברות ועסקים | freesbe',
  FLEET_CAR_LOBBY_DESCRIPTION:
    'מחלקת ציי רכב מציעה של freesbe פתרונות מתקדמים לניהול והפעלת ציי רכב לארגונים ולחברות. אנו מספקים מגוון רחב של רכבים מהדגמים החדשים ביותר בשוק, כולל רכבים חשמליים והיברידיים. המחלקה מעניקה שירות אישי ומותאם לצרכי הלקוח. הצטרפו ללקוחותינו המרוצים ותיהנו משירות מקצועי ומקיף שמקל עליכם את ניהול ציי הרכב שלכם.',
  FLEET_CAR_LISTING: 'מגוון רכבי freebe לציי רכב | freesbe',
  FLEET_CAR_LISTING_BRAND: 'מגוון רכבי {brand} לציי רכב | freesbe	',
  FLEET_CAR_LISTING_DESCRIPTION:
    'מצאו מגוון רחב של דגמי freesbe לציי רכב, כולל רכבים חשמליים והיברידיים, המתאימים לצרכי הארגון שלכם. קבלו פרטים מלאים על כל דגם, כולל מפרט טכני, אבזור בטיחותי, ופתרונות מימון מותאמים אישית. ניהול ציי רכב יעיל ונוח עם רכבי freesbe.',
  COMPARE_TITLE: 'השוואת רכבי יד שנייה | freesbe',
  COMPARE_DESCRIPTION:
    'השווה רכבי יד שנייה באתר freesbe בקלות ובמהירות. מצא את הרכב המתאים לך ביותר בין מגוון רחב של רכבי יד שנייה מבית פריסבי',
};

export const GENERIC_PAGE: IObjMeta = {
  '/privacy': {
    title: META_TEXT.PRIVACY,
    description: META_TEXT.PRIVACY_DESCRIPTION,
    canonical: `${process.env.NEXT_PUBLIC_BASE_URL}${ROUTES.PRIVACY}`,
  },
  '/accessibility': {
    title: META_TEXT.ACCESSIBILITY,
    description: META_TEXT.ACCESSIBILITY_DESCRIPTION,
    canonical: `${process.env.NEXT_PUBLIC_BASE_URL}${ROUTES.ACCESSIBILITY}`,
  },
  '/terms-of-use': {
    title: META_TEXT.TERMS_OF_USE,
    description: META_TEXT.TERMS_OF_USE_DESCRIPTION,
    canonical: `${process.env.NEXT_PUBLIC_BASE_URL}${ROUTES.TERMS_OF_USE}`,
  },
  '/refunds': {
    title: META_TEXT.REFUNDS,
    description: META_TEXT.REFUNDS_DESCRIPTION,
    canonical: `${process.env.NEXT_PUBLIC_BASE_URL}${ROUTES.REFUNDS}`,
  },
  '/eveasy-packages': {
    title: META_TEXT.EVEASY_PACKAGES,
    description: META_TEXT.EVEASY_PACKAGES_DESCRIPTION,
    canonical: `${process.env.NEXT_PUBLIC_BASE_URL}${ROUTES.EVEASY_PACKAGES}`,
  },
  '/terms-and-conditions': {
    title: META_TEXT.CONDITIONS,
    description: META_TEXT.CONDITIONS_DESCRIPTION,
    canonical: `${process.env.NEXT_PUBLIC_BASE_URL}${ROUTES.TERMS_AND_CONDITIONS}`,
  },
  '/service-agreement': {
    title: META_TEXT.SERVICE_AGREEMENT_TITLE,
    description: META_TEXT.SERVICE_AGREEMENT_DESCRIPTION,
    canonical: `${process.env.NEXT_PUBLIC_BASE_URL}${ROUTES.SERVICE_AGREEMENT}`,
  },
  any: {
    title: 'freesbe',
    description: 'freesbe',
    canonical: `${process.env.NEXT_PUBLIC_BASE_URL}`,
  },
};

export interface IMetaData {
  title: string;
  description: string;
  canonical: string;
}
